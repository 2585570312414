<template>
  <div>
    <div class="header-information">
      <div class="box-information-page">
        <h3>Catálogo de Sistemas</h3>
        <div class="indicator"></div>
      </div>

      <div class="box-btn">
        <v-btn
          depressed
          @click="meusFavoritos"
          class="mr-2"
          text
          color="#34495e"
          :loading="loadingFavs"
        >
          <v-icon size="16" class="mr-2" color="#34495e">mdi-star</v-icon>
          Favoritos
        </v-btn>

        <v-btn
          depressed
          color="#34495e"
          @click="exibirFiltro = !exibirFiltro"
          text
        >
          <v-icon size="16" class="mr-2" color="#34495e">mdi-filter</v-icon>
          Filtros
        </v-btn>
      </div>
    </div>

    <v-expand-transition>
      <div class="filter-items" v-if="exibirFiltro">
        <div class="text-filter">
          <v-text-field
            class="mr-2"
            v-model="nomeSistema"
            label="Nome do Sistema"
            placeholder="Nome do Sistema"
            outlined
            dense
          ></v-text-field>
        </div>

        <div class="box-btn">
          <v-btn class="mr-2" depressed color="primary" @click="filtrarDados"
            ><v-icon>mdi-magnify</v-icon></v-btn
          >
          <v-btn depressed color="cyan" class="white--text" @click="exibirTudo">
            Todos
          </v-btn>
        </div>
      </div>
    </v-expand-transition>

    <v-row>
      <v-col
        cols="12"
        md="4"
        sm="4"
        v-for="sistema in sistemas"
        :key="sistema.id"
      >
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto" color="grey lighten-4" max-width="600">
            <a
              target="_blank"
              :href="sistema.link"
              v-if="sistema.image != null"
            >
              <v-img v-bind:src="urlIMG + sistema.image" :aspect-ratio="16 / 9">
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transition-in--out primary darken-2 v-card--reveal white--text content-radius"
                    style="height: 100%"
                  >
                    {{ sistema.description }}
                  </div>
                </v-expand-transition>
              </v-img>
            </a>
            <a target="_blank" :href="sistema.link" v-else>
              <v-img :aspect-ratio="16 / 9" src="../../assets/BG.png">
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transition-in--out primary darken-2 v-card--reveal white--text content-radius"
                    style="height: 100%"
                  >
                    {{ sistema.description }}
                  </div>
                </v-expand-transition>
              </v-img>
            </a>
            <v-card-text class="pt-6 card-text" style="position: relative">
              <v-btn
                absolute
                color="#fdcb6e"
                class="white--text"
                fab
                right
                top
                v-if="verificarEstrela(sistema.id)"
                @click="removeFavorito(sistema.id)"
              >
                <v-icon> mdi-star </v-icon>
              </v-btn>

              <v-btn
                absolute
                color="gray"
                class="black--text"
                fab
                right
                top
                v-if="!verificarEstrela(sistema.id)"
                @click="addFavorito(sistema.id)"
              >
                <v-icon> mdi-star-outline </v-icon>
              </v-btn>
              <a target="_blank" :href="sistema.link" class="black--text mb-2">
                <span class="font-weight-light">
                  {{ sistema.initials }}
                </span>

                <h4>{{ sistema.name }}</h4>
              </a>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="inputPage"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      nomeSistema: "",
      categoria: 0,
      user: [],
      sistemas: [],
      favs: [],
      page: 1,
      totalPages: 0,
      dialog: false,
      exibirFiltro: false,
      filterActive: false,
      dadosTemporarios: "",
      loadingFavs: false,
      // urlIMG: "http://desenv.portalti.saude.ba.gov.br/storage/systems/",
      urlIMG: "https://bi-hgrs.saude.ba.gov.br/storage/dashboards/",
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      axios // BUSCA USUÁRIO LOGADO
        .get(`/auth/me`)
        .then((response) => {
          this.user = response.data;
        });

      axios.get(`/favorities/systems`).then((response) => {
        let resp = response.data.data;
        resp.forEach((element) => {
          this.favs.push(element.id);
        });
      });

      axios.get(`/systems`).then((response) => {
        this.sistemas = response.data.data;
        this.totalPages = response.data.last_page;
      });
    },

    inputPage(event) {
      if (this.filterActive == true) {
        this.page = event;
        axios
          .post(`/systems/filter?page=${event}`, this.dadosTemporarios)
          .then((response) => {
            this.sistemas = response.data.data;
            this.page = response.data.current_page;
            this.totalPages = response.data.last_page;
          });
      } else {
        this.page = event;
        axios.get(`/systems?page=${event}`).then((response) => {
          this.sistemas = response.data.data;
          this.page = response.data.current_page;
          this.totalPages = response.data.last_page;
        });
      }
    },

    verificarEstrela(id) {
      if (this.favs.indexOf(id) != -1) {
        return true;
      }
      return false;
    },

    filtrarDados() {
      var searchInfos = new Object();
      searchInfos.name = this.nomeSistema;

      this.dadosTemporarios = searchInfos;

      axios.post(`/systems/filter`, searchInfos).then((response) => {
        setTimeout(() => {
          this.sistemas = response.data.data;
          this.page = response.data.current_page;
          this.totalPages = response.data.last_page;
          this.filterActive = true;
        }, 1000);
      });
    },

    meusFavoritos() {
      axios.get(`/favorities/systems`).then((response) => {
        this.loadingFavs = true;

        setTimeout(() => {
          this.sistemas = response.data.data;
          this.page = response.data.current_page;
          this.totalPages = response.data.last_page;
          this.filterActive = false;
          this.loadingFavs = false;
        }, 1000);
      });
    },

    exibirTudo() {
      axios.get(`/systems`).then((response) => {
        this.loading = true;
        setTimeout(() => {
          this.sistemas = response.data.data;
          this.page = response.data.current_page;
          this.totalPages = response.data.last_page;
          this.filterActive = false;
        }, 1000);
      });
    },

    addFavorito(idDashboard) {
      var infos = new Object();
      infos.system_id = idDashboard;
      infos.user_id = this.user.id;

      axios.post(`/favorities/systems`, infos).then((response) => {
        this.message = response.data.message;
        this.loading = true;
        setTimeout(() => {
          this.favs.push(idDashboard);
          this.loading = false;
        }, 1000);
      });
    },

    removeFavorito(idDashboard) {
      var infos = new Object();
      infos.system_id = idDashboard;
      infos.user_id = this.user.id;

      axios.post(`/favorities/systems/delete`, infos).then((response) => {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.message = response.data.message;
          this.favs.splice(this.favs.indexOf(idDashboard), 1);
        }, 1000);
      });
    },
  },
};
</script>

<style>
* {
  text-decoration: none;
}

.header-information {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header-information > .box-information-page {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.header-information > .box-information-page > h3 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
}

.header-information > .box-information-page > .indicator {
  height: 2px;
  width: 50px;
  border-radius: 5px;
  margin-right: 10px;
  background: -webkit-linear-gradient(
    160deg,
    rgb(3, 69, 211) 0%,
    rgb(15, 162, 207) 100%
  );
}

.box-btn {
  display: flex;
  justify-content: center;
}

.filter-items {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}

.text-filter {
  display: flex;
}

/* Alterações nos cards e imgs */
.v-image__image.v-image__image--cover,
.content-radius {
  border-radius: 10px;
}

.v-card {
  border-radius: 15px !important;
}

.v-card {
  cursor: pointer;
}

.v-application .grey.lighten-4 {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px !important;
}

.card-text {
  min-height: 100px;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;

  padding: 10px;
  font-family: "Poppins";
  font-size: 12px;
}
/*  */

.card-crud {
  padding: 15px;
}

.card-select-categoria {
  width: 100%;
  min-height: 200px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  cursor: pointer;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

/* .v-card {
  cursor: pointer;
} */
.v-application .grey.lighten-4 {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px !important;
}

.card-text {
  min-height: 125px;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;

  padding: 10px;
  font-family: "Poppins";
  font-size: 12px;
}
</style>
